import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Typography, CircularProgress, Link, Alert } from "@mui/material";
import { useToast } from "@/context/ToastContext";
import api from "@/utils/api";
import ContentLayout from "@/layouts/ContentLayout";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { usePayConfirmTracker } from "../hooks/usePayConfirmTracker";

const COUNTDOWN_DURATION = 10000; // 10秒倒计时

const PayConfirm = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [searchParams] = useSearchParams();
  const payment_intent = searchParams.get("payment_intent");
  const orderId = searchParams.get("orderId");

  const [loading, setLoading] = useState(true);
  const [countdown, setCountdown] = useState(COUNTDOWN_DURATION / 1000);
  const [paymentStatus, setPaymentStatus] = useState<
    "success" | "failed" | null
  >(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  usePayConfirmTracker({
    status: paymentStatus,
  });

  useEffect(() => {
    if (!payment_intent || !orderId) {
      toast("error", "支付確認過程中發生錯誤");
      navigate("/quick-start");
      return;
    }

    const confirmPayment = async () => {
      try {
        const { code, msg } = await api({
          path: `/api/payment-confirm/stripe`,
          method: "post",
          params: {
            paymentIntentId: payment_intent,
            orderId,
          },
        });

        if (code === 0) {
          setPaymentStatus("success");
          toast("success", "支付成功！");
        } else {
          setPaymentStatus("failed");
          setErrorMessage(msg);
          toast("error", msg);
        }
      } catch (error: any) {
        setPaymentStatus("failed");
        setErrorMessage("支付確認過程中發生錯誤");
        toast("error", error.message);
      } finally {
        setLoading(false);
      }
    };
    confirmPayment();
  }, [payment_intent, toast, orderId]);

  useEffect(() => {
    if (paymentStatus === "success") {
      const startTime = Date.now();

      const timer = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const remainingSeconds = Math.ceil(
          (COUNTDOWN_DURATION - elapsedTime) / 1000
        );

        if (remainingSeconds <= 0) {
          clearInterval(timer);
          setCountdown(0);
          location.href = "/#download";
        } else {
          setCountdown(remainingSeconds);
        }
      }, 500);

      return () => clearInterval(timer);
    }
  }, [paymentStatus]);

  const breadcrumbs = [{ name: "首頁", link: "/" }, { name: "支付確認" }];

  return (
    <ContentLayout breadcrumbs={breadcrumbs}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "50vh",
          width: "100%",
          mx: "auto",
          py: 4,
        }}
      >
        {loading && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress size={48} sx={{ mb: 2 }} />
            <Typography variant="h6">正在確認支付狀態...</Typography>
          </Box>
        )}

        {paymentStatus === "success" && (
          <Box sx={{ textAlign: "center" }}>
            <CheckCircleIcon color="success" sx={{ fontSize: 64, mb: 2 }} />
            <Typography variant="h5" gutterBottom>
              支付成功！
            </Typography>
            <Typography variant="body1" gutterBottom>
              <Box
                component="span"
                sx={{
                  color: "primary.main",
                  fontWeight: "bold",
                  fontSize: "1.2em",
                }}
              >
                {countdown}
              </Box>{" "}
              秒後跳轉到下載頁面...
            </Typography>
            <Typography variant="body1" gutterBottom>
              接下來
              <Box
                component="span"
                sx={{
                  color: "primary.main",
                  fontWeight: "bold",
                  mx: 0.5,
                }}
              >
                將引導你
              </Box>
              下載開路者app。
            </Typography>
            <Typography variant="body1">
              您可以點擊{" "}
              <Link href="/#download" underline="hover">
                這裡
              </Link>{" "}
              直接跳轉到下載頁面。
            </Typography>
          </Box>
        )}

        {paymentStatus === "failed" && (
          <Box sx={{ textAlign: "center", maxWidth: 400 }}>
            <ErrorIcon color="error" sx={{ fontSize: 64, mb: 2 }} />
            <Alert severity="error" sx={{ mb: 2 }}>
              {errorMessage}
            </Alert>
            <Typography variant="body1">
              <Link href="/quick-start" underline="hover">
                返回首頁
              </Link>
            </Typography>
          </Box>
        )}
      </Box>
    </ContentLayout>
  );
};

export default PayConfirm;
