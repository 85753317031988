import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { routes, RouteConfig } from "./mods/route";
import { ThemeProvider } from "@/context/ThemeContext";
import PageNoteDialog from "@/components/PageNote";
import AppFooter from "@/layouts/app/AppFooter";
import AppLoading from "@/components/AppLoading";
import useLoading from "@/hooks/useLoading";
import { isApp, isInV1App } from "@/bridge/client";
import { AuthProvider } from "@/context/AuthContext";
import { MyServiceProvider } from "./context/MyServiceContext";
import { VpnProvider } from "./context/VpnContext";

const PageNoteWrapper = ({
  element,
  pageNoteId,
}: {
  element: React.ReactElement;
  pageNoteId?: string;
}) => {
  return (
    <>
      {element}
      {pageNoteId && !isInV1App && <PageNoteDialog pageName={pageNoteId} />}
    </>
  );
};

// 递归渲染路由的辅助函数
const renderRoutes = (routes: RouteConfig[]) => {
  return routes.map((route) => (
    <Route
      key={route.path}
      path={route.path}
      element={
        <PageNoteWrapper
          element={route.element}
          pageNoteId={route.pageNoteId}
        />
      }
    >
      {route.children && renderRoutes(route.children)}
    </Route>
  ));
};

function App() {
  const { loading } = useLoading();

  useEffect(() => {
    console.log("App:loading=", loading, "and isApp=", isApp);
    if (!loading && isApp) {
      const handleSelectStart = (e: any) => {
        e.preventDefault();
      };

      document.addEventListener("selectstart", handleSelectStart);

      // 返回一个函数用于清理事件监听器
      return () => {
        document.removeEventListener("selectstart", handleSelectStart);
      };
    }
  }, [loading]);

  return (
    <AuthProvider>
      <MyServiceProvider>
        <VpnProvider>
          <Router>
            <ThemeProvider>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100vh",
                  overflow: isApp ? "hidden" : "auto",
                }}
              >
                {loading ? (
                  <AppLoading />
                ) : (
                  <Box sx={{ flex: 1 }}>
                    <Routes>{renderRoutes(routes)}</Routes>
                  </Box>
                )}
                <AppFooter />
              </Box>
            </ThemeProvider>
          </Router>
        </VpnProvider>
      </MyServiceProvider>
    </AuthProvider>
  );
}

export default App;
